import { noThumbnailUrl } from "configs/urls";

export default function ThumbnailHooks() {
  const onErrorFunc = (e) => {
    console.log(e.target.src);
    e.target.src = noThumbnailUrl;
  };

  return [onErrorFunc];
}
