import React, { useState, createContext, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";

import CommonDetailBlock from "./CommonDetailBlock";
import { useQuery } from "@tanstack/react-query";
import { RequestDetailedData } from "utils/requestToSrv";

import Loading from "components/Loading";
import { doAnalytics } from "utils/firebaseUtils";

export const DetailContext = createContext();

export default function CommonDetailContainer() {
  const navigate = useNavigate();

  const [mapOpenState, mapOpenSetState] = useState(true);

  const { docId } = useParams();

  const detailedData = useQuery({
    queryKey: ["detailedData", docId],
    queryFn: () => RequestDetailedData({ docId: docId }),
  });

  const goBackButtonFunction = () => {
    navigate(-1);
  };

  const shareButtonFunction = () => {
    if (navigator.canShare) {
      doAnalytics("on_click_share");
      navigator.share({
        title: detailedData.data.title,
        text: detailedData.data.address,
        url: window.location.href,
      });
    } else {
      alert("공유하기 기능을 지원하지 않는 브라우저입니다.");
    }
  };

  const detailInfoButtonFunction = () => {
    doAnalytics("out_link_click");
    window.open(detailedData.data.relatedContentsUrl, "_blank");
  };

  const copyAddressFunc = async () => {
    doAnalytics("onClickCopyAddress");
    try {
      await navigator.clipboard.writeText(detailedData.data.address);
      alert("클립보드에 주소가 복사되었습니다.");
    } catch (error) {
      alert("주소 복사하기에 실패하였습니다.");
    }
  };

  const mapOpenToggleFunc = () => {
    mapOpenSetState((prevState) => !prevState);
  };

  const mapRef = useRef();

  const intersectionCallback = ([entry], observer) => {
    if (entry.isIntersecting) {
      doAnalytics("onClickMap");
      observer.unobserve(entry.target);
    }
  };

  const observer = new IntersectionObserver(intersectionCallback, { threshold: 1 });

  useEffect(() => {
    if (mapRef.current) {
      observer.observe(mapRef.current);
    }
  }, []);

  const detailDataSet = {
    data: detailedData.data,
    shareButtonFunction: shareButtonFunction,
    detailInfoButtonFunction: detailInfoButtonFunction,
    goBackButtonFunction: goBackButtonFunction,
    copyAddressFunc: copyAddressFunc,
    mapOpenState: mapOpenState,
    mapOpenToggleFunc: mapOpenToggleFunc,
    mapRef: mapRef,
  };

  if (detailedData.isLoading) {
    return <Loading />;
  } else {
    return (
      <DetailContext.Provider value={detailDataSet}>
        <CommonDetailBlock />
      </DetailContext.Provider>
    );
  }
}
