import React from "react";

import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import ButtonBase from "@mui/material/ButtonBase";

import AddIcon from "@mui/icons-material/Add";

// Custon Button Styled Component
// Padding은 위아래 여백 / 최소 가로 길이를 고려하여 조정해야 함
// 기본 Background, Hover, Active Color를 필요에 따라 수정
// Color는 자식 요소에 적용이 되므로, 공통적으로 적용할 color를 지정

const CustomButton = styled(Grid)(({ theme }) => ({
  borderRadius: "40px",
  backgroundColor: theme.palette.primary[500],
  padding: "12px 30px",
  color: theme.palette.text.secondary,
  "&:hover": {
    backgroundColor: theme.palette.primary[400],
  },
  "&:active": {
    backgroundColor: theme.palette.primary[500],
  },
}));

// Icon Grid Styled Component
// Icon의 정렬을 위해 사용

const IconGrid = styled(Grid)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

// Outlined Button Styled Component
// 기본 Button과 동일하게 적용
// Border Color를 필요에 따라 수정

const OutlinedCustomButton = styled(Grid)(({ theme }) => ({
  borderRadius: "4px",
  backgroundColor: theme.palette.secondary[300],
  border: `1px solid ${theme.palette.secondary[700]}`,
  padding: "14px 16px",
  color: theme.palette.secondary[700],
  "&:hover": {
    backgroundColor: theme.palette.secondary[500],
  },
  "&:active": {
    backgroundColor: theme.palette.secondary[500],
  },
}));

const ButtonForInput = styled(Grid)(({ theme }) => ({
  borderRadius: "4px",
  backgroundColor: theme.palette.primary[400],
  padding: "8px 12px",
  color: theme.palette.text.secondary,
  "&:hover": {
    backgroundColor: theme.palette.primary[600],
  },
  "&:active": {
    backgroundColor: theme.palette.primary[800],
  },
}));

const CustomButtonBase = styled(ButtonBase)({
  borderRadius: "4px",
  width: "100%",
});

// Default Button
// 필요에 따라 글자 크기를 조정할 수 있도록 children을 props로 받음

export function DefaultButton(props) {
  const { children } = props;
  return (
    <CustomButtonBase {...props}>
      <CustomButton container alignItems="center" justifyContent="center">
        {children}
      </CustomButton>
    </CustomButtonBase>
  );
}

// Icon Button

export function IconButton(props) {
  const { children } = props;
  return (
    <CustomButtonBase {...props}>
      <CustomButton container alignItems="center" justifyContent="center">
        <IconGrid item xs="auto">
          <AddIcon fontSize="small" />
        </IconGrid>
        <Grid item xs>
          {children}
        </Grid>
      </CustomButton>
    </CustomButtonBase>
  );
}

// Outlined Button

export function OutlinedButton(props) {
  const { children } = props;
  return (
    <CustomButtonBase {...props}>
      <OutlinedCustomButton container alignItems="center" justifyContent="center">
        <Grid item xs={12}>
          {children}
        </Grid>
      </OutlinedCustomButton>
    </CustomButtonBase>
  );
}

// Button for Input

export function InputButton(props) {
  const { children } = props;
  return (
    <CustomButtonBase {...props}>
      <ButtonForInput container alignItems="center" justifyContent="center">
        <Grid item xs={12}>
          {children}
        </Grid>
      </ButtonForInput>
    </CustomButtonBase>
  );
}
