import { useRef, useEffect } from "react";
import { mapMarkerUrl } from "configs/urls";

export default function MapHooks(props) {
  const { geopoint } = props;
  const { kakao } = window;

  // 맵 Ref
  const kakaoMapRef = useRef();

  // 위도 / 경도 세팅
  const mapLatLng = new kakao.maps.LatLng(geopoint[0], geopoint[1]);

  // 지도 옵션 (지도 렌더링 시 중심 / 확대 정도)
  const mapOptions = {
    center: mapLatLng,
    level: 3,
  };

  // 마커 이미지 설정
  const markerSize = new kakao.maps.Size(32, 32);

  const markerImage = new kakao.maps.MarkerImage(mapMarkerUrl, markerSize);

  // 마커 위치 설정
  const marker = new kakao.maps.Marker({
    position: mapLatLng,
    image: markerImage,
    clickable: false,
  });

  // 줌 컨트롤 출력
  const zoomControl = new kakao.maps.ZoomControl();

  // 설정값 적용
  useEffect(() => {
    const map = new kakao.maps.Map(kakaoMapRef.current, mapOptions);
    map.addControl(zoomControl, kakao.maps.ControlPosition.RIGHT);
    marker.setMap(map);
    // kakao.maps.event.addListener(marker, "click", function () {
    //   infoWindow.open(map, marker);
    // });
    // infoWindow.open(map, marker);
  }, []);

  return [kakaoMapRef];

  // 마커 상단 말풍선 내용
  // const mapContent = `
  // <div id="containerDiv">
  //   <h5 class="textAlign">REALBE LAB</h5>
  //   <h6 class="textAlign">서울시 마포구 백범로31길 8, <br/> 627호 리얼비연구소</h6>
  //   <h6 class="textAlign">
  //     <a href='https://map.kakao.com/link/map/서울시 마포구 백범로31길 8 627호 리얼비연구소,37.544937,126.949324' class="linkBtn" target='_blank'>
  //     큰지도보기
  //     </a>
  //     <a href='https://map.kakao.com/link/to/서울시 마포구 백범로31길 8 627호 리얼비연구소,37.544937,126.949324' class="linkBtn" target='_blank'>
  //     길찾기
  //     </a>
  //   </h6>
  // </div>
  // `;

  // 마커 상단 말풍선
  // const infoWindow = new kakao.maps.InfoWindow({
  //   position: mapLatLng,
  //   content: mapContent,
  //   removable: true,
  // });
}
