import { createTheme, responsiveFontSizes } from "@mui/material/styles";

const theme = createTheme({
  components: {
    MuiCssBaseline: {
      // 스크롤바 스타일 적용
      styleOverrides: (themeParam) => ({
        body: {
          "::-webkit-scrollbar": {
            width: "5px",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: theme.palette.primary.main,
            borderRadius: "50px",
          },
        },
      }),
    },
    // Typography 컴포넌트의 variantMapping을 통해 커스텀 컴포넌트에 적용할 variant를 지정
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          subtitle1: "h6",
          subtitle2: "h6",
          body1: "p",
          body2: "p",
          body3: "p",
          body4: "p",
        },
      },
    },
  },

  // breakpoints를 통해 xs, sm, md, lg, xl에 대한 사이즈를 지정
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1740,
    },
  },

  // palette를 통해 색상을 지정
  palette: {
    primary: {
      main: "#238C66",
      50: "#EDFFF9",
      100: "#B2FFE3",
      200: "#9DF2D3",
      300: "#8AE5C4",
      400: "#77D9B5",
      500: "#238C66",
      600: "#004029",
      700: "#003320",
      800: "#002618",
      900: "#001910",
    },
    secondary: {
      main: "#42413E",
      50: "#ECECEC",
      100: "#C8C8C7",
      200: "#A4A4A2",
      300: "#807F7C",
      400: "#5C5B57",
      500: "#42413E",
      600: "#333330",
      700: "#252422",
      800: "#161514",
      900: "#070706",
    },
    grey: {
      main: "#C6C6C6",
      50: "#FBFBFB",
      100: "#F4F4F4",
      200: "#EDEDED",
      300: "#E6E6E6",
      400: "#DFDFDF",
      500: "#C6C6C6",
      600: "#9A9A9A",
      700: "#6E6E6E",
      800: "#424242",
      900: "#151515",
    },
    text: {
      primary: "#070706",
      secondary: "#FFFFFF",
    },
    background: {
      default: "#FFFFFF",
    },
  },

  // typography를 통해 폰트를 지정
  typography: {
    fontFamily: "Noto Sans KR",
    h6: {
      fontSize: 24,
      fontWeight: 600,
    },
    subtitle1: {
      fontSize: 20,
      fontWeight: 600,
    },
    subtitle2: {
      fontSize: 14,
      fontWeight: 400,
    },
    body1: {
      fontSize: 13,
      fontWeight: 400,
    },
    body2: {
      fontSize: 12,
      fontWeight: 400,
    },
    body3: {
      fontSize: 11,
      fontWeight: 400,
    },
    body4: {
      fontSize: 10,
      fontWeight: 400,
      fontFamily: "Noto Sans KR",
    },
  },
});

export default theme;
