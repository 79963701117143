import { atom } from "recoil";

export const rootLanding = atom({
  key: "rootLandingState",
  default: false,
});

export const mainLanding = atom({
  key: "mainLandingState",
  default: false,
});
