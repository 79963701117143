import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import ScrollToTop from "../hooks/RouteHooks";

import RootView from "../views/RootView";
import MainView from "../views/MainView";
import DetailView from "../views/DetailView";
import AllPopupsView from "../views/AllPopupsView";
import CommonAllPopupsView from "../views/CommonAllPopupsView";
import CommonDetailView from "../views/CommonDetailView";

const createRoutes = (props) => {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route exact path="/" element={<RootView />} />
        <Route exact path="/:userId" element={<RootView />} />
        <Route exact path="/main/:userId" element={<MainView />} />
        <Route exact path="/all" element={<CommonAllPopupsView />} />
        <Route exact path="/all/:userId" element={<AllPopupsView />} />
        <Route exact path="/detail/:docId" element={<CommonDetailView />} />
        <Route exact path="/detail/:docId/:userId" element={<DetailView />} />
      </Routes>
    </BrowserRouter>
  );
};

export default createRoutes;
