import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { useRecoilState } from "recoil";
import { rootLanding } from "recoils/AnalyticsState";

import RootBlock from "./RootBlock";
import { doAnalytics } from "utils/firebaseUtils";

export default function RootContainer() {
  const navigate = useNavigate();
  const { userId } = useParams();

  const [rootLandingState, rootLandingSetState] = useRecoilState(rootLanding);

  useEffect(() => {
    if (!rootLandingState) {
      doAnalytics("first_landing");
      rootLandingSetState(true);
    }
    setTimeout(() => {
      if (userId) {
        navigate("/main/" + userId);
      } else {
        navigate("/all");
      }
    }, 2000);
  }, []);

  return <RootBlock />;
}
