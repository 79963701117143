import React from "react";

import { styled } from "@mui/material/styles";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

const FrameGrid = styled(Grid)(({ theme }) => ({
  minHeight: "100vh",
  height: "100%",
  padding: "20px 0",
}));

// 공통 화면 사이즈 구성을 위한 Frame
// 개별 화면에서 justifyContent, alignItems를 지정할 수 있도록 Grid container에 props 전달
// Grid container에 props를 전달하지 않고 justifyContent, alignItems를 지정하여 사용할 수도 있음

export default function DefaultFrame(props) {
  const { children } = props;

  return (
    <Container maxWidth="sm">
      <FrameGrid container {...props}>
        {children}
      </FrameGrid>
    </Container>
  );
}
