import { initializeApp, getApps, getApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
import {
  getAuth,
  setPersistence,
  browserSessionPersistence,
  createUserWithEmailAndPassword,
  sendEmailVerification,
  sendPasswordResetEmail,
  updatePassword,
  signInWithEmailAndPassword,
  signInWithPopup,
  GoogleAuthProvider,
  OAuthProvider,
  signOut,
  onAuthStateChanged,
  deleteUser,
  reauthenticateWithCredential,
  getIdToken,
} from "firebase/auth";

const errorCodeMsg = {
  "auth/user-not-found":
    "입력하신 정보와 일치하는 계정이 없습니다. 다시 확인해주세요.",
  "auth/invalid-email": "이메일 형식이 잘못되었습니다.",
  "auth/email-already-in-use": "이미 가입된 이메일 입니다.",
  "auth/missing-password": "비밀번호를 입력해주세요.",
  "auth/wrong-password": "비밀번호가 일치하지 않습니다. 다시 확인해주세요.",
  "auth/popup-closed-by-user": "로그인을 취소하셨습니다.",
};

const firebaseConfigAppKey = {
  apiKey: "AIzaSyDF8nq67VJSyXq3BIA3cY-M5hkS7KipeGw",
  authDomain: "project-poping.firebaseapp.com",
  projectId: "project-poping",
  storageBucket: "project-poping.appspot.com",
  messagingSenderId: "941120982133",
  appId: "1:941120982133:web:9009c821892df7bf387c3c",
  measurementId: "G-CYJH6VHRVQ",
};

const provider = new GoogleAuthProvider();

function alertError(errorCode) {
  if (errorCode === "auth/user-not-found") {
    alert(errorCodeMsg["auth/user-not-found"]);
  } else if (errorCode === "auth/invalid-email") {
    alert(errorCodeMsg["auth/invalid-email"]);
  } else if (errorCode === "auth/email-already-in-use") {
    alert(errorCodeMsg["auth/email-already-in-use"]);
  } else if (errorCode === "auth/missing-password") {
    alert(errorCodeMsg["auth/missing-password"]);
  } else if (errorCode === "auth/wrong-password") {
    alert(errorCodeMsg["auth/wrong-password"]);
  } else {
    alert("알 수 없는 이유로 가입에 실패하였습니다.");
  }
}

function doCreateUserWithEmailAndPassword({
  email,
  password,
  callBack,
  loadSetState,
}) {
  const app =
    getApps().length === 0 ? initializeApp(firebaseConfigAppKey) : getApp();

  createUserWithEmailAndPassword(getAuth(), email, password)
    .then((userCredential) => {
      callBack(userCredential.user.accessToken);
    })
    .catch((error) => {
      const errorCode = error.code;
      alert(errorCodeMsg[errorCode]);
      loadSetState(false);
    });
}

function doSignInWithEmailAndPassword({
  email,
  password,
  callBack,
  loadSetState,
}) {
  const app =
    getApps().length === 0 ? initializeApp(firebaseConfigAppKey) : getApp();

  setPersistence(getAuth(), browserSessionPersistence)
    .then(() => {
      signInWithEmailAndPassword(getAuth(), email, password)
        .then((userCredential) => {
          callBack(userCredential);
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          loadSetState(false);
          alertError(errorCode);
        });
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      loadSetState(false);
      alertError(errorCode);
    });
}

function doSignInWithGoogle({ callBack }) {
  const app =
    getApps().length === 0 ? initializeApp(firebaseConfigAppKey) : getApp();

  setPersistence(getAuth(), browserSessionPersistence)
    .then((userCredential) => {
      signInWithPopup(getAuth(), provider)
        .then((userCredential) => {
          callBack(userCredential);
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          alert(errorCodeMsg[errorCode]);
        });
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      alert(errorCodeMsg[errorCode]);
    });
}

function doSendEmailVarification(callBack) {
  const app =
    getApps().length === 0 ? initializeApp(firebaseConfigAppKey) : getApp();

  sendEmailVerification(getAuth().currentUser)
    .then(() => {
      callBack();
    })
    .catch((error) => {
      console.log(error);
      return false;
    });
}

function doOnAuthStateChanged(authedSetState, loadSetState, callBack) {
  const app =
    getApps().length === 0 ? initializeApp(firebaseConfigAppKey) : getApp();

  onAuthStateChanged(getAuth(), (userCredential) => {
    if (userCredential) {
      callBack({ token: userCredential.accessToken });
    } else {
      authedSetState(false);
      loadSetState(false);
      return false;
    }
  });
}

function doOnGetAuth(loadSetState, successSetState, userSetState, RequestData) {
  const app =
    getApps().length === 0 ? initializeApp(firebaseConfigAppKey) : getApp();

  onAuthStateChanged(getAuth(), (user) => {
    if (user) {
      successSetState(true);

      RequestData({
        setLoadingState: loadSetState,
        setRequestResult: userSetState,
        formData: {
          type: "sign_in",
          token: user.accessToken,
        },
      });

      return true;
    } else {
      successSetState(false);
      loadSetState(false);
      return false;
    }
  });
}

async function doOnGetToken() {
  const app =
    getApps().length === 0 ? initializeApp(firebaseConfigAppKey) : getApp();

  if (getAuth().currentUser == null) {
    // console.log("get_token_none");
    return false;
  } else {
    // console.log("get_token_done");
    // return getAuth().currentUser;
    const token = getAuth().currentUser.getIdToken(true);
    return token;
  }
}

function doGetEmailValidation() {
  const app =
    getApps().length === 0 ? initializeApp(firebaseConfigAppKey) : getApp();
  return getAuth().currentUser.emailVerified;
}

function doSignOut(querySignOut) {
  const app =
    getApps().length === 0 ? initializeApp(firebaseConfigAppKey) : getApp();

  signOut(getAuth())
    .then(() => {
      querySignOut();
    })
    .catch((error) => {
      console.log(error);
    });
}

function doSignOutWithNoQuery() {
  const app =
    getApps().length === 0 ? initializeApp(firebaseConfigAppKey) : getApp();

  signOut(getAuth()).catch((error) => {
    console.log(error);
  });
}

function doSendPasswordResetEmail({ loadSetState, email }) {
  const app =
    getApps().length === 0 ? initializeApp(firebaseConfigAppKey) : getApp();
  sendPasswordResetEmail(getAuth(), email)
    .then(() => {
      loadSetState(false);
    })
    .catch((error) => {
      loadSetState(false);
      console.log(error);
      alert(errorCodeMsg[error.code]);
    });
}

function doUpdatePassword({ loadSetState, callBack, newPassword }) {
  const app =
    getApps().length === 0 ? initializeApp(firebaseConfigAppKey) : getApp();

  updatePassword(getAuth().currentUser, newPassword)
    .then(() => {
      loadSetState(false);
      callBack();
    })
    .catch((error) => {
      loadSetState(false);
      console.log(error);
      alert(errorCodeMsg[error.code]);
    });
}

function doDeleteUser({ loadSetState, callBack }) {
  const app =
    getApps().length === 0 ? initializeApp(firebaseConfigAppKey) : getApp();

  deleteUser(getAuth().currentUser)
    .then(() => {
      loadSetState(false);
      callBack();
    })
    .catch((error) => {
      console.log(error);
      alert(errorCodeMsg[error.code]);
      loadSetState(false);
    });
}

function doAnalytics(eventName) {
  const app =
    getApps().length === 0 ? initializeApp(firebaseConfigAppKey) : getApp();
  const analytics = getAnalytics(app);

  logEvent(analytics, eventName);
}

export {
  doCreateUserWithEmailAndPassword,
  doSignInWithEmailAndPassword,
  doSignInWithGoogle,
  doSendEmailVarification,
  doOnAuthStateChanged,
  doOnGetAuth,
  doOnGetToken,
  doGetEmailValidation,
  doSignOut,
  doSignOutWithNoQuery,
  doUpdatePassword,
  doSendPasswordResetEmail,
  doDeleteUser,
  doAnalytics,
};
