import { createContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";

import { useRecoilState } from "recoil";
import { mainLanding } from "recoils/AnalyticsState";

import { RequestCurate } from "utils/requestToSrv";
import { doAnalytics } from "utils/firebaseUtils";

import Loading from "components/Loading";

// import GeolocationHooks from "hooks/GeolocationHooks";

import RootBlock from "./MainBlock";

export const MainContext = createContext();

export default function MainContainer() {
  const { userId } = useParams();

  const [mainLandingCheckState, mainLandingCheckSetState] = useRecoilState(mainLanding);

  useEffect(() => {
    if (!mainLandingCheckState) {
      doAnalytics("visit_" + userId);
      mainLandingCheckSetState(true);
    }
  }, []);

  const navigate = useNavigate();

  // const [geolocation] = GeolocationHooks();

  // const printLocation = () => {
  //   if (geolocation) {
  //     alert(geolocation.coords.latitude + ", " + geolocation.coords.longitude);
  //   }
  // };

  const onMoveDetail = (docId) => {
    doAnalytics("detail_page_click");
    navigate("/detail/" + docId + "/" + userId);
  };

  const getCurateData = useQuery({
    queryKey: ["curateData"],
    queryFn: () => RequestCurate({ userId: userId }),
  });

  const linkToAllCuration = () => {
    doAnalytics("onClickAllList");
    navigate("/all/" + userId);
  };

  const mainValue = {
    curateData: getCurateData.data,
    onMoveDetail: onMoveDetail,
    userId: userId,
    // printLocation: printLocation,
    linkToAllCuration: linkToAllCuration,
  };

  if (getCurateData.isLoading) {
    return <Loading />;
  } else
    return (
      <MainContext.Provider value={mainValue}>
        <RootBlock />
      </MainContext.Provider>
    );
}
