import React from "react";

import { styled, keyframes } from "@mui/material/styles";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

import { logoUrl } from "configs/urls";

const fadeIn = keyframes`
  0% {
    opacity: 0
  }
  100% {
    opacity: 1
  }
`;

const FrameGridContainer = styled(Grid)({
  minHeight: "100vh",
  height: "100%",
  animation: `${fadeIn} 2s ease-in-out`,
});

const LogoImage = styled("img")({
  width: "100%",
});

export default function RootBlock() {
  return (
    <Container maxWidth="sm" disableGutters>
      <FrameGridContainer container item justifyContent="center" alignItems="center">
        <Grid item xs={8}>
          <LogoImage src={logoUrl} alt="로고" />
        </Grid>
      </FrameGridContainer>
    </Container>
  );
}
