import { createContext, useState, useEffect } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";

import { RequestAllTags, RequestFilteredData, RequestBannerInfo, RequestAllPopupsWithEndedPopups, RequestAllPopupsWithoutEndedPopups } from "utils/requestToSrv";
import { doAnalytics } from "utils/firebaseUtils";

import Loading from "components/Loading";

import CommonAllPopupsBlock from "./CommonAllPopupsBlock";

export const AllPopupsContext = createContext();
export const FilterContext = createContext();
export const EndedPopupsContext = createContext();

export default function CommonPopupsContainer() {
  const navigate = useNavigate();

  const [filterMenuState, filterMenuSetState] = useState(false);
  const [currentFilterState, currentFilterSetState] = useState("all");
  const [popupDataState, popupDataSetState] = useState();
  const [checkedState, checkedSetState] = useState(true);
  const [filterMoreState, filterMoreSetState] = useState(false);

  useEffect(() => {
    if (currentFilterState === "all") {
      if (checkedState) {
        popupDataSetState(allPopupsWithoutEndedPopups.data);
      } else {
        popupDataSetState(allPopupsWithEndedPopups.data);
      }
    } else {
      filteredData.mutate();
    }
  }, [currentFilterState, checkedState]);

  const filterMenuToggleFunc = () => {
    filterMenuSetState((prevState) => !prevState);
  };

  const filterSelectFunc = (filter) => {
    if (filter === currentFilterState) {
      currentFilterSetState("all");
      filterMenuSetState(false);
    } else {
      doAnalytics("onClickTagFilter");
      currentFilterSetState(filter);
      filterMenuSetState(false);
    }
  };

  const checkToggle = () => {
    checkedSetState((prevState) => !prevState);
  };

  const filterMoreOpenFunc = () => {
    filterMoreSetState(true);
  };

  const filterMoreCloseFunc = () => {
    filterMoreSetState(false);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const onMoveDetail = (docId) => {
    doAnalytics("detail_page_click");
    navigate("/detail/" + docId);
  };

  const allTags = useQuery({
    queryKey: ["allTags"],
    queryFn: () => RequestAllTags(),
  });

  const bannerInfo = useQuery({
    queryKey: ["bannerInfo"],
    queryFn: () => RequestBannerInfo(),
  });

  const allPopupsWithEndedPopups = useQuery({
    queryKey: ["allPopupsWithEndedPopups"],
    queryFn: () => RequestAllPopupsWithEndedPopups(),
  });

  const allPopupsWithoutEndedPopups = useQuery({
    queryKey: ["allPopupsWithoutEndedPopups"],
    queryFn: () => RequestAllPopupsWithoutEndedPopups({ popupDataSetState: popupDataSetState }),
  });

  const filteredData = useMutation({
    mutationFn: () => RequestFilteredData({ tag: currentFilterState, endedPopups: checkedState }),
    onSuccess: (data) => {
      popupDataSetState(data);
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const toTopButtonFunc = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const bannerButtonClickFunc = () => {
    doAnalytics("onClickBanner");
    if (bannerInfo.data) {
      window.open(bannerInfo.data.linkTo, "_blank");
    }
  };

  const allPopupsValue = {
    bannerInfo: bannerInfo.data,
    popupDataState: popupDataState,
    onMoveDetail: onMoveDetail,
    toTopButtonFunc: toTopButtonFunc,
    bannerButtonClickFunc: bannerButtonClickFunc,
  };

  const filterValue = {
    filterMenuState: filterMenuState,
    filterMenuToggleFunc: filterMenuToggleFunc,
    currentFilterState: currentFilterState,
    wholeFilterState: allTags.data,
    filterSelectFunc: filterSelectFunc,
    checkedState: checkedState,
    checkToggle: checkToggle,
    filterMoreState: filterMoreState,
    filterMoreOpenFunc: filterMoreOpenFunc,
    filterMoreCloseFunc: filterMoreCloseFunc,
  };

  const endedPopupsValue = {
    checkedState: checkedState,
    checkToggle: checkToggle,
  };

  if (allTags.isLoading || bannerInfo.isLoading || allPopupsWithEndedPopups.isLoading || allPopupsWithoutEndedPopups.isLoading || filteredData.isPending) {
    return <Loading />;
  } else {
    return (
      <AllPopupsContext.Provider value={allPopupsValue}>
        <FilterContext.Provider value={filterValue}>
          <EndedPopupsContext.Provider value={endedPopupsValue}>
            <CommonAllPopupsBlock />
          </EndedPopupsContext.Provider>
        </FilterContext.Provider>
      </AllPopupsContext.Provider>
    );
  }
}
