import React from "react";

import { styled } from "@mui/material/styles";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";

// Data Loading 중일 때 Block 대신 return하여 Loading 화면 출력

const LoadingGrid = styled(Grid)({
  height: "100vh",
});

export default function Loading() {
  return (
    <Container>
      <LoadingGrid container justifyContent="center" alignItems="center">
        <Grid item>
          <CircularProgress color="primary" />
        </Grid>
      </LoadingGrid>
    </Container>
  );
}
