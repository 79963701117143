import React, { useContext } from "react";

import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import ButtonBase from "@mui/material/ButtonBase";
import IconButton from "@mui/material/IconButton";

import DefaultFrame from "components/DefaultFrame";
import PopUpCard from "components/PopUpCard";
import { toTopIconUrl, expandFilterIconUrl, checkIconUrl } from "configs/urls";

import { AllPopupsContext, FilterContext, EndedPopupsContext } from "./CommonAllPopupsContainer";

const MainContainerGrid = styled(Grid)({
  paddingTop: "60px",
});

const HeaderContainerGrid = styled(Grid)(({ theme }) => ({
  zIndex: 10,
  position: "fixed",
  top: 0,
  left: 0,
  backgroundColor: theme.palette.background.default + "DD",
  padding: "20px 0",
}));

const HeaderItemGrid = styled(Grid)(({ theme }) => ({
  width: theme.breakpoints.values.sm,
  padding: "0 24px",
}));

const FilterContainerGrid = styled(Grid)(({ theme }) => ({
  backgroundColor: theme.palette.grey[100],
  padding: "10px 10px 10px 18px",
  borderRadius: 8,
}));

const FilterChipsScrollContainerGrid = styled(Grid)(({ theme }) => ({
  maxHeight: "60px",
  overflowY: "auto",
  "&::-webkit-scrollbar": {
    width: "5px",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: theme.palette.primary.main,
    borderRadius: "50px",
    minHeight: "20px",
  },
}));

const FilterIconGrid = styled(Grid)(({ open }) => ({
  display: "flex",
  alignItems: "center",
  transform: open ? "rotate(0deg)" : "rotate(180deg)",
  transition: "transform 0.5s",
}));

const FilterCountTypo = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary[300],
}));

const FilterButton = styled(ButtonBase)({
  borderRadius: 20,
});

const FilterButtonGrid = styled(Grid)(({ theme, active }) => ({
  background: theme.palette.background.default,
  borderRadius: 20,
  border: active ? `1px solid ${theme.palette.primary.main}` : "1px solid #E3E3E3",
  padding: "6px 18px",
}));

const FilterTypo = styled(Typography)(({ theme, active }) => ({
  color: active ? theme.palette.primary.main : theme.palette.text.primary,
}));

const EmptyFilterGrid = styled(Grid)({
  width: 18,
});

const TitleTypo = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
}));

const MoreFilterTypo = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary[400],
}));

const BannerButton = styled(ButtonBase)({
  width: "100%",
});

const BannerImage = styled("img")({
  width: "100%",
});

const ToTopButtonBox = styled(Box)({
  zIndex: 10,
  position: "fixed",
  bottom: 20,
  right: 20,
});

const EndedPopupCheckedTypo = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary[500],
}));

const EndedPopupUncheckedTypo = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[600],
}));

const IconGrid = styled(Grid)({
  display: "flex",
  alignItems: "center",
});

export default function CommonAllPopupsBlock() {
  const { popupDataState, onMoveDetail } = useContext(AllPopupsContext);
  return (
    <DefaultFrame>
      <TitleBarBlock />
      <MainContainerGrid container item alignContent="flex-start" rowGap={2.5}>
        <FilterBlock />
        <BannerBlock />
        <EndedPopupButtonBlock />
        <Grid container item rowGap={2}>
          {popupDataState &&
            popupDataState.map((curation, index) => {
              return <PopUpCard index={index} key={"popupCard" + index} docId={curation.documentId} onMoveDetail={onMoveDetail} {...curation} />;
            })}
        </Grid>
      </MainContainerGrid>
      <ToTopButtonBlock />
    </DefaultFrame>
  );
}

function TitleBarBlock() {
  return (
    <HeaderContainerGrid container justifyContent="center">
      <HeaderItemGrid item>
        <TitleTypo variant="subtitle1" align="center">
          전체 팝업 리스트
        </TitleTypo>
      </HeaderItemGrid>
    </HeaderContainerGrid>
  );
}

function FilterBlock() {
  const { filterMenuState, currentFilterState, filterMenuToggleFunc, wholeFilterState, filterMoreState, filterMoreOpenFunc, filterMoreCloseFunc } = useContext(FilterContext);

  return (
    <Grid container item rowGap={2.5}>
      <FilterContainerGrid container item rowGap={1.5}>
        <Grid container item justifyContent="space-between" alignItems="center">
          <Grid item>
            {currentFilterState === "all" ? (
              <Typography variant="subtitle2" fontWeight={500}>
                해시태그를 선택해주세요
              </Typography>
            ) : (
              <Typography variant="subtitle2" fontWeight={600}>
                #{currentFilterState}
              </Typography>
            )}
          </Grid>

          <Grid container item xs="auto">
            {currentFilterState === "all" && (
              <Grid container item xs="auto" alignItems="center" columnSpacing={0.3}>
                <Grid item>
                  <Typography variant="body3" color="primary" fontWeight={700}>
                    {wholeFilterState.length}
                  </Typography>
                </Grid>
                <Grid item>
                  <FilterCountTypo variant="body3" fontWeight={400}>
                    개의 해시태그가 있어요
                  </FilterCountTypo>
                </Grid>
              </Grid>
            )}
            <FilterIconGrid item open={filterMenuState}>
              <IconButton onClick={filterMenuToggleFunc}>
                <img src={expandFilterIconUrl} alt="필터 메뉴" />
              </IconButton>
            </FilterIconGrid>
          </Grid>
        </Grid>
        {filterMenuState && filterMoreState ? (
          <Grid container item>
            <Grid container item xs columnSpacing={1.5} rowGap={1.5}>
              {wholeFilterState.map((filter, index) => (
                <FilterChip key={"filter" + index} filter={filter} />
              ))}
            </Grid>
            <EmptyFilterGrid item />
          </Grid>
        ) : filterMenuState && !filterMoreState ? (
          <Grid container item>
            <FilterChipsScrollContainerGrid container item xs columnSpacing={1.5} rowGap={1.5}>
              {wholeFilterState.map((filter, index) => (
                <FilterChip key={"filter" + index} filter={filter} />
              ))}
            </FilterChipsScrollContainerGrid>
            <EmptyFilterGrid item />
          </Grid>
        ) : null}
        {filterMenuState && (
          <Grid container item justifyContent="center">
            {filterMoreState ? (
              <Grid item>
                <ButtonBase disableRipple onClick={filterMoreCloseFunc}>
                  <Grid container direction="column" justifyContent="center" alignItems="center">
                    <Grid item>
                      <MoreFilterTypo variant="body1">간단히 보기</MoreFilterTypo>
                    </Grid>
                    <FilterIconGrid item open={filterMoreState}>
                      <img src={expandFilterIconUrl} alt="간단히 보기" />
                    </FilterIconGrid>
                  </Grid>
                </ButtonBase>
              </Grid>
            ) : (
              <Grid item>
                <ButtonBase disableRipple onClick={filterMoreOpenFunc}>
                  <Grid container direction="column" justifyContent="center" alignItems="center">
                    <Grid item>
                      <MoreFilterTypo variant="body1">전체 해시태그 보기</MoreFilterTypo>
                    </Grid>
                    <FilterIconGrid item open={filterMoreState}>
                      <img src={expandFilterIconUrl} alt="전체 해시태그 보기" />
                    </FilterIconGrid>
                  </Grid>
                </ButtonBase>
              </Grid>
            )}
          </Grid>
        )}
      </FilterContainerGrid>
    </Grid>
  );
}

function FilterChip(props) {
  const { filter } = props;
  const { currentFilterState, filterSelectFunc } = useContext(FilterContext);

  const activeState = currentFilterState === filter;

  return (
    <Grid item>
      <FilterButton onClick={() => filterSelectFunc(filter)}>
        <FilterButtonGrid container active={activeState}>
          <FilterTypo variant="body2" active={activeState}>
            #{filter}
          </FilterTypo>
        </FilterButtonGrid>
      </FilterButton>
    </Grid>
  );
}

function BannerBlock() {
  const { bannerInfo, bannerButtonClickFunc } = useContext(AllPopupsContext);

  if (bannerInfo) {
    return (
      <Grid item xs={12}>
        <BannerButton onClick={bannerButtonClickFunc}>
          <BannerImage src={bannerInfo.imageUrl} alt="배너" />
        </BannerButton>
      </Grid>
    );
  }
}

function EndedPopupButtonBlock() {
  const { checkedState, checkToggle } = useContext(EndedPopupsContext);

  return (
    <Grid container item justifyContent="flex-end">
      <Grid item>
        <ButtonBase onClick={checkToggle}>
          {checkedState ? (
            <Grid container item alignItems="center" columnSpacing={0.5}>
              <IconGrid item>
                <img src={checkIconUrl.checked} alt="종료된 팝업 체크" />
              </IconGrid>
              <Grid item>
                <EndedPopupCheckedTypo variant="body1">종료된 팝업 제외</EndedPopupCheckedTypo>
              </Grid>
            </Grid>
          ) : (
            <Grid container item alignItems="center" columnSpacing={0.5}>
              <IconGrid item>
                <img src={checkIconUrl.unchecked} alt="종료된 팝업 체크" />
              </IconGrid>
              <Grid item>
                <EndedPopupUncheckedTypo variant="body1">종료된 팝업 제외</EndedPopupUncheckedTypo>
              </Grid>
            </Grid>
          )}
        </ButtonBase>
      </Grid>
    </Grid>
  );
}

function ToTopButtonBlock() {
  const { toTopButtonFunc } = useContext(AllPopupsContext);

  return (
    <ToTopButtonBox>
      <IconButton size="small" onClick={toTopButtonFunc}>
        <img src={toTopIconUrl} alt="toTopButton" />
      </IconButton>
    </ToTopButtonBox>
  );
}
