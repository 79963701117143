import React, { useContext } from "react";

import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import ButtonBase from "@mui/material/ButtonBase";

import { backIconUrl, detailIconUrls } from "configs/urls";
import { DefaultButton } from "components/Buttons";
import KakaoMap from "components/KakaoMap";

import { DetailContext } from "./CommonDetailContainer";

import DefaultFrame from "components/DefaultFrame";

const DetailContentsGrid = styled(Grid)({
  padding: "0 22px",
  aspectRatio: "1/1",
});

const ThumbnailGrid = styled(Grid)({
  overflow: "hidden",
});

const ThumbnailImg = styled("img")({
  borderRadius: "10px",
  width: "100%",
  objectFit: "cover",
});

const DetailTitleTypo = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
}));

const DetailTitleGrid = styled(Grid)(({ theme }) => ({
  borderBottom: `2.5px solid ${theme.palette.primary.main}`,
  padding: "15px 0",
}));

const DetailSummaryTypo = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary[400],
}));

const DetailContentsTypo = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary[600],
}));

const MapExpandIcon = styled("img")(({ open }) => ({
  transform: open ? "rotate(0deg)" : "rotate(180deg)",
  transition: "transform 0.5s",
}));

const MapGrid = styled(Grid)(({ open }) => ({
  position: "relative",
  height: "50vh",
  display: open ? "block" : "none",
}));

const TitleTypo = styled(Typography)({
  overflow: "hidden",
  textOverflow: "ellipsis",
  display: "-webkit-box",
  WebkitBoxOrient: "vertical",
  WebkitLineClamp: 2,
});

export default function CommonDetailBlock() {
  return (
    <DefaultFrame alignContent="flex-start" rowGap={1}>
      <GoBackBar />
      <DetailContentsGrid container item rowGap={8}>
        <Grid container item rowGap={1}>
          <ThumbnailBlock />
          <DetailedInfoBlock />
        </Grid>
        <MapBlock />
      </DetailContentsGrid>
    </DefaultFrame>
  );
}

function GoBackBar() {
  const { goBackButtonFunction } = useContext(DetailContext);
  return (
    <Grid container item>
      <IconButton onClick={goBackButtonFunction}>
        <img src={backIconUrl} alt="back" />
      </IconButton>
    </Grid>
  );
}

function ThumbnailBlock() {
  const { data } = useContext(DetailContext);
  return (
    <ThumbnailGrid item xs={12}>
      <ThumbnailImg src={data.thumbnailImgUrl} alt="thumbnail" />
    </ThumbnailGrid>
  );
}

function DetailedInfoBlock() {
  const { data, shareButtonFunction, detailInfoButtonFunction } = useContext(DetailContext);

  return (
    <Grid container item rowGap={4}>
      <DetailTitleGrid container item alignItems="center">
        <Grid item xs={12}>
          <DetailTitleTypo variant="subtitle2" align="center" fontWeight={600}>
            팝업정보
          </DetailTitleTypo>
        </Grid>
      </DetailTitleGrid>
      <Grid container item rowGap={3.25}>
        <Grid container item justifyContent="space-between">
          <Grid item xs={11}>
            <TitleTypo variant="h6">{data.title}</TitleTypo>
          </Grid>
          <Grid item xs={1}>
            <IconButton onClick={shareButtonFunction}>
              <img src={detailIconUrls.share} alt="share" />
            </IconButton>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <DetailSummaryTypo variant="subtitle2">{data.description}</DetailSummaryTypo>
        </Grid>
        <Grid container item columnSpacing={1}>
          <Grid item>
            <img src={detailIconUrls.location} alt="location" />
          </Grid>
          <Grid item xs>
            <DetailContentsTypo variant="body2">{data.address}</DetailContentsTypo>
          </Grid>
        </Grid>
        <Grid container item columnSpacing={1}>
          <Grid item>
            <img src={detailIconUrls.calendar} alt="calendar" />
          </Grid>
          <Grid container item xs>
            <Grid item>
              <DetailContentsTypo variant="body2">{data.startDate}</DetailContentsTypo>
            </Grid>
            <Grid item>
              <DetailContentsTypo variant="body2">~</DetailContentsTypo>
            </Grid>
            <Grid item>
              <DetailContentsTypo variant="body2">{data.endDate}</DetailContentsTypo>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <DefaultButton onClick={detailInfoButtonFunction}>
          <Typography variant="subtitle2" fontWeight={700}>
            상세 정보 보러가기
          </Typography>
        </DefaultButton>
      </Grid>
    </Grid>
  );
}

function MapBlock() {
  const { data, copyAddressFunc, mapOpenState, mapOpenToggleFunc, mapRef } = useContext(DetailContext);

  return (
    <Grid container item rowGap={2}>
      <Grid container item justifyContent="space-between" alignItems="center">
        <Grid item>
          <DetailContentsTypo variant="body1" fontWeight={500}>
            팝업 스토어 위치
          </DetailContentsTypo>
        </Grid>
        <Grid item>
          <IconButton onClick={mapOpenToggleFunc}>
            <MapExpandIcon src={detailIconUrls.expand} alt="expand" open={mapOpenState} />
          </IconButton>
        </Grid>
      </Grid>
      <Grid container item rowGap={1}>
        <MapGrid item ref={mapRef} xs={12} open={mapOpenState}>
          <KakaoMap geopoint={data.geopoint} />
        </MapGrid>
        <Grid container item justifyContent="flex-end">
          <Grid item>
            <ButtonBase onClick={copyAddressFunc}>
              <Typography variant="body4">주소 복사</Typography>
            </ButtonBase>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
