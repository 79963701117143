import axios from "axios";

const url = "https://asia-northeast3-project-poping.cloudfunctions.net/function-curation-test";

const api = axios.create({
  baseURL: url,
  headers: {
    "Content-type": "application/json; charset=UTF-8",
  },
});

const detailedDataFormData = {
  type: "detail_shared",
};

const requestCurateFormData = {
  type: "curation_test",
};

const allTagsFormData = {
  type: "all_tags",
};

const filteredDataFormData = {
  type: "tag_filter",
};

const allPopupsFormData = {
  type: "all_stores_map",
  lastDocId: "",
};

const bannerInfoFormData = {
  type: "banner_info",
};

// @ts-check
/**
 * @description 개인별 큐레이션 데이터 요청
 * @param {{ userId: string }}
 * @returns {Promise<object>}
 */

export const RequestCurate = ({ userId }) => {
  const queryFunc = api.post("/", JSON.stringify({ ...requestCurateFormData, userId: userId })).then((post) => post.data);
  return queryFunc;
};

// @ts-check
/**
 * @description detail 페이지 데이터 요청
 * @param {{ docId: string }}
 * @returns {Promise<object>}
 */

export const RequestDetailedData = ({ docId }) => {
  const queryFunc = api.post("/", JSON.stringify({ ...detailedDataFormData, docId: docId })).then((post) => post.data);
  return queryFunc;
};

// @ts-check
/**
 * @description 전체 태그 데이터 요청
 * @returns {Array<string>}
 */

export const RequestAllTags = () => {
  const queryFunc = api.post("/", JSON.stringify({ ...allTagsFormData })).then((post) => post.data);
  return queryFunc;
};

// @ts-check
/**
 * @description 전체 팝업스토어 데이터 요청
 * @returns {Promise<object>}
 */

export const RequestAllPopupsWithEndedPopups = () => {
  const queryFunc = api.post("/", JSON.stringify({ ...allPopupsFormData, ended_popups: false })).then((post) => post.data);
  return queryFunc;
};

// @ts-check
/**
 * @description 종료된 팝업 제외 전체 팝업스토어 데이터 요청
 * @returns {Promise<object>}
 */

export const RequestAllPopupsWithoutEndedPopups = ({ popupDataSetState }) => {
  const queryFunc = api.post("/", JSON.stringify({ ...allPopupsFormData, ended_popups: true })).then((post) => {
    popupDataSetState(post.data);
    return post.data;
  });
  return queryFunc;
};

// @ts-check
/**
 * @description tag 기반 filter가 적용된 팝업스토어 데이터 요청
 * @param {{ tag: string, endedPopups: boolean}}
 * @returns {Promise<object>}
 */

export const RequestFilteredData = ({ tag, endedPopups }) => {
  const queryFunc = api.post("/", JSON.stringify({ ...filteredDataFormData, tag: tag, ended_popups: endedPopups })).then((post) => post.data);
  return queryFunc;
};

// @ts-check
/**
 * @description 배너 정보 요청
 * @returns {Promise<object>}
 */

export const RequestBannerInfo = () => {
  const queryFunc = api.post("/", JSON.stringify({ ...bannerInfoFormData })).then((post) => post.data);
  return queryFunc;
};
