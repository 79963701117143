import React, { useContext } from "react";
import { Link } from "react-router-dom";

import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import DefaultFrame from "components/DefaultFrame";
import PopUpCard from "components/PopUpCard";
import { DefaultButton } from "components/Buttons";

import { MainContext } from "./MainContainer";

const MainContainerGrid = styled(Grid)({
  paddingBottom: "60px",
});

const HeaderContainerGrid = styled(Grid)({
  padding: "20px 0",
});

const HeaderItemGrid = styled(Grid)(({ theme }) => ({
  width: theme.breakpoints.values.sm,
  padding: "0 24px",
}));

const TitleTypo = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[700],
}));

const FooterIconGrid = styled(Grid)(({ theme }) => ({
  zIndex: 10,
  position: "fixed",
  bottom: 0,
  left: 0,
  padding: "20px 0",
}));

const FooterItemGrid = styled(Grid)(({ theme }) => ({
  width: theme.breakpoints.values.sm,
  padding: "0 24px",
}));

export default function MainBlock() {
  const { curateData, onMoveDetail } = useContext(MainContext);
  return (
    <DefaultFrame>
      <MainContainerGrid container item alignContent="flex-start" rowGap={2.5}>
        <TitleBarBlock />
        <Grid container item rowGap={2}>
          {curateData.data.map((curation, index) => {
            return <PopUpCard index={index} docId={curation.documentId} onMoveDetail={onMoveDetail} {...curation} />;
          })}
        </Grid>
      </MainContainerGrid>
      <FooterBlock />
    </DefaultFrame>
  );
}

function TitleBarBlock() {
  const { curateData } = useContext(MainContext);
  // const { curateData, printLocation } = useContext(MainContext);

  return (
    <HeaderContainerGrid container justifyContent="center">
      <HeaderItemGrid container item justifyContent="center">
        {/* <Grid item onClick={printLocation}> */}
        <Grid item>
          <TitleTypo variant="subtitle1">{curateData.instaId}</TitleTypo>
        </Grid>
      </HeaderItemGrid>
    </HeaderContainerGrid>
  );
}

function FooterBlock() {
  const { linkToAllCuration } = useContext(MainContext);
  return (
    <FooterIconGrid container justifyContent="center">
      <FooterItemGrid container item justifyContent="center">
        <Grid item>
          <DefaultButton onClick={linkToAllCuration}>
            <Typography variant="body1" fontWeight={700}>
              전체 팝업 보기
            </Typography>
          </DefaultButton>
        </Grid>
      </FooterItemGrid>
    </FooterIconGrid>
  );
}
