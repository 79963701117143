import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import ButtonBase from "@mui/material/ButtonBase";

import ThumbnailHooks from "hooks/ThumbnailHooks";

const CategoryTypo = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary[300],
}));

const AddressTypo = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary[300],
}));

const ThumbnailImg = styled("img")({
  borderRadius: "10px",
  width: "100%",
  objectFit: "cover",
  aspectRatio: "1/1",
});
const ComponentButton = styled(ButtonBase)({
  width: "100%",
  textAlign: "left",
});

export default function PopUpCard(props) {
  const {
    onMoveDetail,
    docId,
    category,
    title,
    startDate,
    endDate,
    address,
    thumbnailImgUrl,
  } = props;

  const [onErrorFunc] = ThumbnailHooks();

  return (
    <Grid item xs={12}>
      <ComponentButton onClick={() => onMoveDetail(docId)}>
        <Grid container columnSpacing={1.2}>
          <Grid container item xs={3} justifyContent="center">
            <ThumbnailImg
              src={thumbnailImgUrl}
              onError={onErrorFunc}
              alt="Thumbnail"
            />
          </Grid>
          <Grid container item xs={9} alignItems="center">
            <Grid container item rowSpacing={1}>
              <Grid item xs={12}>
                <CategoryTypo variant="body4">{category}</CategoryTypo>
              </Grid>
              <Grid container item>
                <Grid item xs={12}>
                  <Typography variant="body2" fontWeight={500} noWrap>
                    {title}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body2" fontWeight={400}>
                    {startDate}~{endDate}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid container item columnSpacing={0.5}>
              <Grid item>
                <AddressTypo variant="body4">
                  {address.split(" ")[0]}
                </AddressTypo>
              </Grid>
              <Grid item>
                <AddressTypo variant="body4">
                  {address.split(" ")[1]}
                </AddressTypo>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </ComponentButton>
    </Grid>
  );
}
