import React from "react";

import Box from "@mui/material/Box";
import styled from "@mui/material/styles/styled";

import "./KakaoMap.css";

import MapHooks from "hooks/MapHooks";

const KakaoMapBox = styled(Box)({
  position: "absolute",
  top: "0%",
  zIndex: 8,
  width: "100%",
  height: "100%",
});

export default function KakaoMap(props) {
  const { geopoint } = props;

  const [kakaoMapRef] = MapHooks({ geopoint });

  return <KakaoMapBox ref={kakaoMapRef}></KakaoMapBox>;
}
