// image 파일의 위치가 직접적으로 노출되는 것을 방지하기 위해 url을 저장, import 하여 사용
export const logoUrl = "/assets/icons/logo.svg";

export const backIconUrl = "/assets/icons/back.svg";

export const toTopIconUrl = "/assets/icons/to_top.svg";

export const detailIconUrls = {
  share: "/assets/icons/share.svg",
  location: "/assets/icons/location.svg",
  calendar: "/assets/icons/calendar.svg",
  expand: "/assets/icons/expand.svg",
};

export const expandFilterIconUrl = "/assets/icons/expand_filter.svg";

export const mapMarkerUrl = "/assets/icons/map_marker.svg";

export const checkIconUrl = {
  checked: "/assets/icons/check_green.svg",
  unchecked: "/assets/icons/check_grey.svg",
};

export const noThumbnailUrl = "/assets/images/No_thumbnail.svg";
